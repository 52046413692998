import React, { useState, useEffect } from 'react';

//this component is image with scroll event changes color in every scroll
const ExperienceImg = () => {
  //I only want to change h property in hsl color standard
  const [h, setH] = useState(0);

  //color template
  const color = `hsl(${h}, 100%, 75%)`;

  //h property change event function
  const scrollColorChange = () => {
    setH(window.scrollY / 4 - 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollColorChange);
  }, []);

  return (
    <div id='experienceImg'>
      <svg
        width='327'
        height='397'
        viewBox='0 0 327 397'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='experienceImg 1' clipPath='url(#clip0)'>
          <g id='Warstwa 2'>
            <g id='ligtbulb_sun'>
              <path
                id='Path 73'
                d='M143.099 53.395C139.907 57.022 148.224 63.526 153.576 63.684C158.928 63.842 167.793 58.776 163.994 56.244C160.195 53.712 150.061 45.48 143.099 53.395Z'
                fill={color}
              />
              <path
                id='Path 74'
                d='M197.699 10.02C197.699 10.02 199.451 31.549 205.15 34.081C210.849 36.613 218.236 31.548 216.126 25.849C214.016 20.15 211.48 -4.14124e-06 211.48 -4.14124e-06C211.48 -4.14124e-06 196.255 -0.311004 197.699 10.02Z'
                fill={color}
              />
              <path
                id='Path 75'
                d='M247.572 17.376C240.433 14.629 224.644 29.381 223.299 38.047C221.954 46.713 236.362 55.915 211.48 68.274C186.598 80.633 194.38 91.074 203.248 101.201C212.116 111.328 251.371 134.128 262.769 125.896C274.167 117.664 310.546 91.071 290.769 58.144C270.992 25.217 249.469 36.615 249.469 29.65C249.469 22.685 255.171 20.3 247.572 17.376Z'
                fill={color}
              />
              <path
                id='Path 76'
                d='M305.193 37.985C301.756 39.685 285.564 47.631 293.793 50.038C302.022 52.445 305.193 47.515 311.523 46.497C317.853 45.479 314.299 33.476 305.193 37.985Z'
                fill={color}
              />
              <path
                id='Path 77'
                d='M223.299 151.223C227.337 155.584 250.655 151.223 245.631 145.841C240.607 140.459 218.023 145.525 223.299 151.223Z'
                fill={color}
              />
              <path
                id='Path 78'
                d='M221.928 162.621C224.777 163.887 246.045 169.147 243.009 163.255C239.973 157.363 224.778 157.555 224.778 163.889'
                fill={color}
              />
            </g>
            <g id='man'>
              <path
                id='Path 79'
                d='M84.84 303.824C84.84 303.824 93.075 308.889 99.405 298.759C105.735 288.629 128.699 253.8 131.781 251.9C134.863 250 116.889 238.7 112.581 245.3C108.273 251.9 80.408 299.391 84.84 303.824Z'
                fill='#F2F2F2'
              />
              <path
                id='Path 80'
                d='M207.681 288.643C207.681 288.643 170.845 320.143 153.647 313.955C143.095 310.155 155.712 294.481 161.141 290.025C166.57 285.569 143.546 300.658 133.822 298.125C124.098 295.592 144.56 267.471 153.647 265.174C162.734 262.877 216.726 281.359 207.681 288.643Z'
                fill='#F2F2F2'
              />
              <path
                id='Path 81'
                d='M184.253 370.942C184.253 370.942 186.887 383.606 197.6 377.274C208.313 370.942 244.144 342.448 243.009 337.383C241.874 332.318 239.341 319.021 218.445 338.649C197.549 358.277 184.253 370.942 184.253 370.942Z'
                fill='#F2F2F2'
              />
              <path
                id='Path 82'
                d='M151.799 251.268C153.75 275.158 188.525 260.168 182.948 250.549C172.969 233.343 154.69 274.719 153.118 281.915C152.727 283.706 155.473 284.468 155.866 282.673C157.182 276.648 160.219 270.759 163.031 265.328C165.507 260.545 170.62 249.476 177.147 249.483C177.778 249.509 178.391 249.701 178.925 250.039C179.459 250.378 179.894 250.85 180.187 251.41C180.48 251.97 180.621 252.597 180.595 253.228C180.569 253.859 180.377 254.472 180.039 255.006C178.277 257.733 174.567 259.389 171.639 260.426C163.484 263.315 155.418 260.745 154.644 251.267C154.496 249.451 151.644 249.437 151.795 251.267L151.799 251.268Z'
                fill='#35357E'
              />
              <path
                id='Path 83'
                d='M159.813 242.93C148.946 247.386 152.839 229.771 155.425 224.655C158.518 218.538 165.2 212.016 172.368 215.371C174.852 216.533 167.307 218.437 166.568 218.527C162.959 218.968 159.468 217.39 156.756 215.088C152.085 211.123 155.626 205.355 159.019 202.044C163.403 197.767 169.219 196.071 175.181 195.692C191.789 194.634 206.969 207.151 207.525 224.043C207.585 225.875 210.434 225.882 210.374 224.043C209.655 202.243 186.849 187.633 166.393 194.352C159.958 196.466 152.908 201.972 151.743 209.007C150.568 216.116 158.487 221.007 164.643 221.442C167.007 221.608 180.216 218.727 175.289 214.048C168.466 207.568 159.579 213.877 155.223 219.841C151.17 225.43 149.251 232.286 149.814 239.167C150.343 245.435 154.831 248.033 160.573 245.678C162.247 244.989 161.515 242.232 159.815 242.93H159.813Z'
                fill='#35357E'
              />
              <path
                id='Path 84'
                d='M207.522 222.142C207.563 224.461 208.327 226.71 209.708 228.574C211.088 230.439 213.016 231.826 215.222 232.542C217.975 233.509 223.845 233.658 224.649 229.858C225.28 226.875 222.262 224.289 220.027 222.932C218.374 221.893 216.488 221.281 214.54 221.149C212.592 221.017 210.641 221.37 208.863 222.176C207.201 222.959 208.648 225.414 210.301 224.637C211.469 224.14 212.731 223.905 214 223.948C215.268 223.992 216.511 224.313 217.642 224.89C219.342 225.673 221.442 227.09 221.881 229.039C222.281 230.788 217.057 230.084 216.505 229.951C214.765 229.507 213.22 228.501 212.111 227.088C211.002 225.676 210.391 223.937 210.373 222.141C210.327 220.306 207.478 220.301 207.524 222.141L207.522 222.142Z'
                fill='#35357E'
              />
              <path
                id='Path 85'
                d='M207.94 222.4C183.68 253.266 189.731 296.283 213.971 325.248C226.797 340.575 245.02 351.415 264.813 354.489C283.413 357.378 305.794 352.815 316.979 336.315C326.697 321.977 333.679 288.257 313.511 279.164C311.851 278.416 310.4 280.87 312.073 281.625C329.252 289.37 323.592 316.117 317.517 329.441C307.972 350.38 282.201 355.005 261.661 351.063C220.648 343.193 191.378 300.404 195.525 259.581C196.922 246.733 201.924 234.541 209.954 224.415C211.076 222.987 209.074 220.956 207.94 222.4V222.4Z'
                fill='#35357E'
              />
              <path
                id='Path 86'
                d='M228.49 331.015C231.333 333.123 234.424 334.873 237.695 336.224C239.395 336.924 240.126 334.167 238.452 333.476C235.42 332.189 232.557 330.536 229.927 328.553C228.447 327.462 227.027 329.936 228.49 331.015Z'
                fill='#35357E'
              />
              <path
                id='Path 87'
                d='M262.391 346.988C273.611 348.767 284.128 346.818 294.514 342.412C296.201 341.697 294.747 339.243 293.076 339.951C283.489 344.018 273.508 345.882 263.147 344.24C261.347 343.956 260.579 346.701 262.391 346.988V346.988Z'
                fill='#35357E'
              />
              <path
                id='Path 88'
                d='M304.299 338.39L306.199 336.49C306.466 336.223 306.616 335.86 306.616 335.483C306.616 335.105 306.466 334.743 306.198 334.475C305.931 334.208 305.569 334.058 305.191 334.059C304.813 334.059 304.451 334.209 304.184 334.476L302.284 336.376C302.017 336.643 301.867 337.006 301.867 337.384C301.867 337.761 302.017 338.124 302.284 338.391C302.551 338.658 302.914 338.808 303.292 338.808C303.669 338.808 304.032 338.658 304.299 338.391V338.39Z'
                fill='#35357E'
              />
              <path
                id='Path 89'
                d='M184.252 188.74C186.596 188.584 188.946 188.95 191.131 189.812C192.786 190.581 194.231 188.126 192.569 187.351C189.941 186.247 187.099 185.747 184.252 185.89C183.881 185.9 183.528 186.054 183.269 186.32C183.01 186.586 182.865 186.943 182.865 187.315C182.865 187.686 183.01 188.043 183.269 188.309C183.528 188.575 183.881 188.729 184.252 188.739V188.74Z'
                fill='#35357E'
              />
              <path
                id='Path 90'
                d='M205.781 196.972C206.036 197.006 206.274 197.118 206.461 197.294C206.603 197.4 206.715 197.542 206.783 197.706C206.991 198.059 206.739 197.515 206.86 197.9C206.981 198.285 206.874 197.681 206.889 198.08C206.884 198.27 206.917 198.46 206.986 198.637C207.056 198.814 207.16 198.976 207.293 199.112C207.425 199.248 207.584 199.357 207.759 199.431C207.935 199.505 208.123 199.543 208.314 199.543C208.504 199.543 208.692 199.505 208.868 199.431C209.043 199.357 209.202 199.248 209.334 199.112C209.467 198.976 209.571 198.814 209.641 198.637C209.71 198.46 209.743 198.27 209.738 198.08C209.687 197.047 209.254 196.069 208.523 195.338C207.792 194.606 206.814 194.173 205.781 194.122C205.403 194.122 205.041 194.272 204.773 194.539C204.506 194.807 204.356 195.169 204.356 195.547C204.356 195.925 204.506 196.287 204.773 196.555C205.041 196.822 205.403 196.972 205.781 196.972V196.972Z'
                fill='#35357E'
              />
              <path
                id='Path 91'
                d='M146.52 273.056C142.809 276.832 134.666 285.065 140.286 290.312C145.228 294.926 155.341 287.657 160.57 286.835L158.77 285.462C158.97 293.162 147.438 305.974 161.77 306.7C170.092 307.122 178.396 303.549 185.538 299.658C192.773 295.717 199.656 290.373 204.485 283.648C205.559 282.148 203.085 280.732 202.025 282.209C193.861 293.578 178.354 302.66 164.379 303.787C162.508 303.938 157.956 304.421 157.101 302.116C156.501 300.495 157.844 297.994 158.438 296.539C159.903 292.959 161.726 289.427 161.623 285.461C161.618 285.244 161.564 285.03 161.465 284.837C161.367 284.643 161.226 284.474 161.053 284.342C160.88 284.21 160.68 284.119 160.467 284.075C160.254 284.031 160.034 284.035 159.823 284.088C155.216 284.813 151.04 287.288 146.523 288.428C141.886 289.595 140.394 286.479 142.562 282.488C144.205 279.753 146.216 277.257 148.539 275.071C149.827 273.76 147.813 271.744 146.524 273.057L146.52 273.056Z'
                fill='#35357E'
              />
              <path
                id='Path 92'
                d='M109.91 339.657C117.039 331.657 123.482 323.085 130.6 315.077C138.043 306.819 146.539 299.577 155.871 293.535C173.34 282.046 193.505 276.023 213.757 271.635C215.557 271.247 214.791 268.5 213 268.887C192.389 273.349 172.311 279.623 154.432 291.07C145.33 296.924 137.009 303.91 129.668 311.862C122.025 320.119 115.379 329.24 107.894 337.638C106.675 339.006 108.684 341.028 109.909 339.653L109.91 339.657Z'
                fill='#35357E'
              />
              <path
                id='Path 93'
                d='M189.232 307.587C191.143 308.508 194.749 309.86 194.371 312.573C194.171 313.991 193.044 315.363 192.265 316.517C190.344 319.064 188.881 321.925 187.942 324.974C187.88 325.216 187.882 325.471 187.948 325.712C188.014 325.953 188.141 326.173 188.318 326.35C188.495 326.527 188.715 326.654 188.956 326.72C189.197 326.786 189.452 326.788 189.694 326.726C193.816 325.604 197.985 323.709 202.317 323.726C203.577 323.732 199.417 335.317 199.289 336.748C199.29 336.967 199.341 337.182 199.438 337.378C199.536 337.574 199.677 337.745 199.851 337.878C200.025 338.01 200.227 338.101 200.442 338.144C200.656 338.186 200.878 338.178 201.089 338.121C206.489 336.053 212.017 341.958 215.515 345.326C219.847 349.496 223.784 353.991 228.31 357.961C242.91 370.772 256.986 384.173 271.888 396.642C273.288 397.812 275.313 395.808 273.903 394.627C258.114 381.413 242.984 367.373 227.666 353.618C222.63 349.096 218.436 343.747 213.202 339.418C209.689 336.518 205.002 333.584 200.333 335.371L202.133 336.744C202.422 333.418 208.625 324.325 204.594 321.583C200.602 318.868 192.973 322.872 188.933 323.973L190.685 325.725C191.901 321.052 195.803 317.878 197.114 313.325C198.314 309.165 193.69 306.58 190.664 305.125C189.017 304.325 187.571 306.786 189.226 307.585L189.232 307.587Z'
                fill='#35357E'
              />
              <path
                id='Path 94'
                d='M12.399 302.451C-5.301 299.893 6.44401 275.464 11.191 266.307C14.292 260.146 18.0111 254.316 22.291 248.907C29.056 240.584 38.791 233.947 47.546 227.943C66.294 215.094 88.146 203.791 110.432 198.655C114.02 197.828 117.957 197.179 121.584 198.107C123.654 198.637 124.557 200.225 124.629 202.256C124.792 206.769 120.94 207.465 117.737 205.52C110.909 201.374 118.681 186.12 121.384 180.558C122.907 177.421 130.713 161.82 134.124 169.964C135.713 173.764 134.102 177.846 131.237 180.47C131.051 180.641 128.285 182.535 128.049 181.946C127.464 180.48 129.677 177.737 130.396 176.637C131.406 175.094 128.937 173.668 127.936 175.198C126.48 177.425 122.573 186.18 129.121 184.698C134.508 183.483 137.729 177.59 137.531 172.407C137.349 167.602 133.694 162.422 128.699 165.46C123.206 168.801 119.924 176.755 117.421 182.329C114.73 188.323 110.752 196.982 112.594 203.521C114.221 209.293 122.735 211.7 126.371 206.772C130.089 201.732 125.85 195.645 120.523 194.991C113.888 194.177 106.989 196.391 100.76 198.446C81.9125 204.757 63.9865 213.544 47.452 224.574C35.566 232.45 23.635 241.334 15.596 253.274C8.48201 263.852 -13.28 301.6 11.646 305.2C13.44 305.457 14.215 302.712 12.402 302.45L12.399 302.451Z'
                fill='#35357E'
              />
              <path
                id='Path 95'
                d='M43.086 223.493C44.9508 226.42 46.596 229.481 48.008 232.651C48.699 234.325 51.457 233.592 50.756 231.893C49.2667 228.489 47.5255 225.2 45.547 222.055C45.3496 221.742 45.0385 221.519 44.6794 221.431C44.3202 221.343 43.941 221.398 43.6217 221.585C43.3025 221.771 43.0682 222.075 42.9682 222.431C42.8683 222.787 42.9105 223.168 43.086 223.493V223.493Z'
                fill='#35357E'
              />
              <path
                id='Path 96'
                d='M61.305 212.389C63.139 216.767 64.536 221.309 66.37 225.689C67.07 227.357 69.827 226.625 69.118 224.931C67.284 220.553 65.887 216.011 64.053 211.631C63.353 209.962 60.596 210.695 61.305 212.389V212.389Z'
                fill='#35357E'
              />
              <path
                id='Path 97'
                d='M77.136 195.926C78.987 201.926 81.444 207.712 83.467 213.656C84.055 215.386 86.808 214.643 86.215 212.898C84.192 206.954 81.735 201.173 79.884 195.168C79.344 193.418 76.594 194.168 77.136 195.926Z'
                fill='#35357E'
              />
              <path
                id='Path 98'
                d='M137.359 169.672C138.831 166.79 141.831 162.886 145.522 164.755C149.237 166.636 147.283 171.931 146.669 174.826C145.961 178.159 143.569 181.52 141.421 184.068C140.094 185.646 138.521 187.106 136.373 187.204C131.853 187.409 133.291 182.604 135.237 180.724C136.56 179.445 134.544 177.432 133.222 178.709C129.689 182.126 129.053 189.346 135.442 189.963C141.952 190.592 146.319 183.037 148.581 177.921C150.525 173.521 152.369 165.91 147.463 162.63C142.333 159.2 137.212 163.705 134.899 168.23C134.064 169.863 136.522 171.304 137.358 169.668L137.359 169.672Z'
                fill='#35357E'
              />
              <path
                id='Path 99'
                d='M151.068 168.06C151.563 167.44 153.401 164.75 154.315 165.983C155.38 167.419 155.482 169.69 155.602 171.391C155.866 175.134 155.827 180.078 153.895 183.43C153.721 183.755 153.68 184.135 153.78 184.49C153.88 184.845 154.114 185.148 154.432 185.334C154.751 185.52 155.129 185.576 155.487 185.489C155.846 185.402 156.157 185.18 156.355 184.869C159.033 180.225 158.93 173.321 158.077 168.213C157.764 166.34 157.012 163.878 155.103 163.055C152.628 161.987 150.446 164.295 149.053 166.045C147.921 167.468 149.923 169.498 151.067 168.06H151.068Z'
                fill='#35357E'
              />
              <path
                id='Path 100'
                d='M159.522 173.471C163.122 167.787 166.281 161.853 170.072 156.287C174.009 150.51 179.753 142.842 187.418 142.516L186.045 141.47C187.857 149.691 180.88 159.34 176.678 165.808C171.743 173.408 166.052 180.464 160.451 187.573C159.326 188.999 161.33 191.03 162.466 189.588C168.472 181.967 174.504 174.388 179.795 166.236C184.314 159.28 190.727 149.484 188.795 140.711C188.713 140.41 188.534 140.145 188.286 139.955C188.037 139.766 187.734 139.664 187.422 139.665C180.168 139.973 174.649 145.54 170.464 150.965C165.352 157.594 161.527 164.982 157.064 172.03C156.076 173.59 158.544 175.017 159.523 173.47L159.522 173.471Z'
                fill='#35357E'
              />
              <path
                id='Path 101'
                d='M151.326 194.123C147.155 193.908 144.786 189.975 146.887 186.249C149.792 181.093 156.531 187.223 156.847 191.116C157.521 199.435 150.989 208.568 146.747 215.242C136.623 231.171 124.51 246.631 110.363 259.165C102.742 265.917 93.814 271.91 83.663 273.839C76.004 275.294 57.374 271.332 66.739 260.661C70.828 256.001 77.661 254.641 83.453 253.651C91.603 252.258 100.066 252.876 108.269 253.328C117.211 253.827 126.036 255.594 134.48 258.577C143.898 261.897 154.457 267.783 156.918 278.243C157.338 280.031 160.086 279.273 159.666 277.485C155.648 260.404 133.897 254.206 118.906 251.639C107.665 249.72 96.2121 249.37 84.875 250.6C77.008 251.488 65.075 254.2 61.985 262.872C59.446 269.995 65.375 274.447 71.592 276.172C79.929 278.483 88.592 276.23 96.138 272.53C114.012 263.763 127.614 246.525 139.259 230.912C144.362 224.216 149.047 217.211 153.287 209.938C156.661 204.011 162.127 194.324 158.876 187.321C156.351 181.885 148.276 179.51 144.428 184.815C140.477 190.269 145.602 196.682 151.328 196.976C153.165 197.07 153.155 194.221 151.328 194.127L151.326 194.123Z'
                fill='#35357E'
              />
              <path
                id='Path 102'
                d='M213.792 172.226C216.68 172.782 219.621 173.012 222.56 172.91C222.75 172.915 222.94 172.882 223.117 172.813C223.294 172.743 223.456 172.639 223.592 172.506C223.728 172.374 223.837 172.215 223.911 172.04C223.985 171.864 224.023 171.676 224.023 171.486C224.023 171.295 223.985 171.107 223.911 170.931C223.837 170.756 223.728 170.597 223.592 170.465C223.456 170.332 223.294 170.228 223.117 170.158C222.94 170.089 222.75 170.056 222.56 170.061C219.902 170.177 217.24 169.982 214.627 169.479C212.84 169.062 212.003 171.809 213.791 172.227L213.792 172.226Z'
                fill='#35357E'
              />
              <path
                id='Path 103'
                d='M226.57 170.6C227.577 176.805 226.37 187.069 218.52 187.862C210.39 188.682 206.92 174.646 208.42 168.701C208.872 166.919 206.12 166.163 205.672 167.943C203.572 176.248 208.372 190.806 218.518 190.711C228.618 190.617 230.542 177.411 229.318 169.842C229.026 168.032 226.281 168.803 226.572 170.6H226.57Z'
                fill='#35357E'
              />
              <path
                id='Path 104'
                d='M207.767 167.089C204.105 165.358 202.134 160.307 206.501 158.152C208.149 157.339 206.701 154.882 205.063 155.691C198.834 158.764 200.734 166.904 206.329 169.549C207.981 170.33 209.429 167.874 207.767 167.088V167.089Z'
                fill='#35357E'
              />
              <path
                id='Path 105'
                d='M205.527 156.182C195.312 152.524 200.227 142.164 202.09 134.506C202.525 132.721 199.776 131.963 199.342 133.748C197.001 143.368 192.565 154.56 204.77 158.929C206.503 159.55 207.243 156.796 205.527 156.182V156.182Z'
                fill='#35357E'
              />
              <path
                id='Path 106'
                d='M202.722 133.873C206.467 114.609 192.471 98.714 188.108 80.879C183.15 60.608 196.597 44.645 215.842 40.414C234.502 36.314 256.054 44.173 262.425 63.331C265.85 73.631 264.468 85.914 260.637 95.89C258.211 102.106 254.407 107.691 249.511 112.225C245.174 116.27 238.986 117.113 234.532 121.09C233.16 122.316 235.182 124.325 236.547 123.105C240.976 119.149 246.995 118.126 251.526 114.24C256.109 110.31 259.504 105.001 262.106 99.618C266.564 90.0649 268.126 79.415 266.599 68.984C263.589 49.691 247.851 38.115 229.058 36.671C213.019 35.439 195.412 42.322 187.666 57.032C177.53 76.279 191.637 95.695 198.006 113.462C200.282 119.812 201.27 126.447 199.973 133.116C199.625 134.916 202.373 135.676 202.721 133.873H202.722Z'
                fill='#35357E'
              />
              <path
                id='Path 107'
                d='M194.01 126.9C206.497 138.969 230.235 144.617 241.837 128.511C242.914 127.017 240.437 125.597 239.377 127.073C228.906 141.608 207.038 135.53 196.024 124.885C194.704 123.609 192.686 125.622 194.01 126.9V126.9Z'
                fill='#35357E'
              />
              <path
                id='Path 108'
                d='M192.399 137.89C202.083 143.032 222.988 152.857 231.074 140.545C232.086 139.003 229.618 137.578 228.614 139.106C221.789 149.498 201.848 139.683 193.837 135.429C192.216 134.568 190.775 137.029 192.399 137.89V137.89Z'
                fill='#35357E'
              />
              <path
                id='Path 109'
                d='M213.486 130.073C215.169 118.925 221.786 109.282 223.286 98.097C224.112 91.928 225.032 83.86 217.646 81.761C212.446 80.284 208.707 84.331 210.717 89.282C213.443 95.995 223.009 96.846 229.099 95.792C234.747 94.815 245.744 90.964 244.378 83.16C243.049 75.574 233.647 80.973 231.149 84.65C227.006 90.75 225.83 98.91 224.921 106.05C224.533 109.105 222.333 123.579 226.934 124.731C228.717 125.177 229.473 122.431 227.691 121.983C226.671 121.728 227.328 110.963 227.411 109.728C227.783 104.557 228.668 99.4367 230.052 94.441C231.397 89.5 233.546 83.932 238.906 82.23C242.878 80.97 241.306 85.348 240.568 86.714C239.602 88.3427 238.175 89.6491 236.468 90.469C233.655 91.9512 230.577 92.8639 227.411 93.155C222.541 93.774 215.29 93.024 213.164 87.791C211.577 83.884 216.304 84.074 218.606 85.08C221.217 86.221 221.027 90.447 220.977 92.714C220.688 105.759 212.65 116.643 210.738 129.309C210.467 131.109 213.212 131.88 213.486 130.068V130.073Z'
                fill='#35357E'
              />
              <path
                id='Path 110'
                d='M196.88 68.994C199.62 62.884 202.794 56.383 208.4 52.409C209.885 51.356 208.465 48.883 206.962 49.948C200.9 54.248 197.393 60.926 194.42 67.555C193.674 69.217 196.13 70.667 196.88 68.994V68.994Z'
                fill='#35357E'
              />
              <path
                id='Path 111'
                d='M219.164 49.243C220.947 48.38 223.041 47.55 225.032 48.119C225.392 48.2051 225.771 48.148 226.089 47.96C226.408 47.7719 226.64 47.4674 226.739 47.1109C226.837 46.7543 226.793 46.3736 226.616 46.049C226.438 45.7243 226.142 45.4812 225.789 45.371C223.023 44.579 220.215 45.576 217.727 46.78C216.072 47.58 217.519 50.039 219.164 49.242V49.243Z'
                fill='#35357E'
              />
              <path
                id='Path 112'
                d='M209.666 60.007C210.878 59.442 212.242 59.307 213.466 58.741C213.791 58.5495 214.028 58.2371 214.123 57.8719C214.219 57.5067 214.167 57.1185 213.977 56.792C213.781 56.4714 213.469 56.239 213.106 56.1436C212.742 56.0482 212.356 56.097 212.028 56.28C210.816 56.845 209.452 56.98 208.228 57.546C207.902 57.7375 207.666 58.0501 207.57 58.4155C207.474 58.781 207.527 59.1694 207.717 59.496C207.913 59.8162 208.226 60.0482 208.589 60.1434C208.952 60.2387 209.338 60.1898 209.666 60.007V60.007Z'
                fill='#35357E'
              />
              <path
                id='Path 113'
                d='M158.924 65.267C164.924 65.227 170.982 64.676 176.909 65.849C178.703 66.204 179.468 63.458 177.666 63.101C171.471 61.875 165.194 62.374 158.925 62.416C158.556 62.4298 158.207 62.586 157.951 62.8517C157.695 63.1175 157.552 63.4721 157.552 63.841C157.552 64.21 157.695 64.5646 157.951 64.8303C158.207 65.096 158.556 65.2522 158.925 65.266L158.924 65.267Z'
                fill='#35357E'
              />
              <path
                id='Path 114'
                d='M177.676 56.841C179.251 57.5108 180.963 57.7943 182.669 57.668C182.859 57.673 183.049 57.6399 183.226 57.5706C183.403 57.5013 183.565 57.3972 183.701 57.2644C183.837 57.1316 183.946 56.9729 184.02 56.7975C184.094 56.6222 184.132 56.4338 184.132 56.2435C184.132 56.0532 184.094 55.8648 184.02 55.6895C183.946 55.5141 183.837 55.3554 183.701 55.2226C183.565 55.0898 183.403 54.9857 183.226 54.9164C183.049 54.8471 182.859 54.814 182.669 54.819C181.44 54.9282 180.202 54.7786 179.035 54.38C177.382 53.602 176.014 56.058 177.676 56.841Z'
                fill='#35357E'
              />
              <path
                id='Path 115'
                d='M201.875 10.4C203.235 16.838 205.475 23.1 206.256 29.65C206.47 31.45 209.322 31.473 209.105 29.65C208.296 22.829 206.037 16.341 204.621 9.643C204.242 7.843 201.495 8.608 201.874 10.4H201.875Z'
                fill='#35357E'
              />
              <path
                id='Path 116'
                d='M239.182 22.051C239.246 24.4554 238.659 26.8323 237.482 28.93C236.507 30.494 238.972 31.923 239.942 30.369C241.375 27.8351 242.098 24.9621 242.036 22.052C242.026 21.6807 241.872 21.328 241.606 21.0688C241.34 20.8097 240.983 20.6647 240.612 20.6647C240.24 20.6647 239.883 20.8097 239.617 21.0688C239.351 21.328 239.197 21.6807 239.187 22.052L239.182 22.051Z'
                fill='#35357E'
              />
              <path
                id='Path 117'
                d='M265.388 56.841C269.152 55.0728 273.064 53.6377 277.078 52.552C277.431 52.4417 277.727 52.1987 277.905 51.874C278.082 51.5494 278.126 51.1687 278.028 50.8121C277.929 50.4556 277.697 50.1511 277.378 49.963C277.06 49.7749 276.681 49.7179 276.321 49.804C272.072 50.9647 267.933 52.4955 263.951 54.379C262.3 55.188 263.745 57.645 265.388 56.841V56.841Z'
                fill='#35357E'
              />
              <path
                id='Path 118'
                d='M136.763 326.144C137.044 326.192 137.332 326.171 137.604 326.085C137.875 325.998 138.122 325.848 138.323 325.646C138.525 325.445 138.675 325.198 138.762 324.927C138.848 324.655 138.869 324.367 138.821 324.086C138.811 323.715 138.657 323.362 138.391 323.103C138.125 322.844 137.768 322.699 137.396 322.699C137.025 322.699 136.668 322.844 136.402 323.103C136.136 323.362 135.982 323.715 135.972 324.086C135.972 324.224 135.905 324.667 136 324.153C136.065 323.915 136.204 323.703 136.396 323.548C136.589 323.393 136.825 323.303 137.072 323.29C136.972 323.3 136.865 323.29 136.763 323.29C136.392 323.3 136.039 323.454 135.78 323.72C135.521 323.986 135.376 324.343 135.376 324.715C135.376 325.086 135.521 325.443 135.78 325.709C136.039 325.975 136.392 326.129 136.763 326.139V326.144Z'
                fill='#35357E'
              />
              <path
                id='Path 119'
                d='M144.677 313.641C144.667 313.27 144.513 312.917 144.247 312.658C143.981 312.399 143.624 312.254 143.253 312.254C142.881 312.254 142.524 312.399 142.258 312.658C141.992 312.917 141.838 313.27 141.828 313.641C141.823 313.831 141.856 314.021 141.925 314.198C141.995 314.375 142.099 314.537 142.232 314.673C142.364 314.809 142.523 314.918 142.698 314.992C142.874 315.066 143.062 315.104 143.253 315.104C143.443 315.104 143.631 315.066 143.807 314.992C143.982 314.918 144.141 314.809 144.273 314.673C144.406 314.537 144.51 314.375 144.58 314.198C144.649 314.021 144.682 313.831 144.677 313.641V313.641Z'
                fill='#35357E'
              />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width='326.491' height='397' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ExperienceImg;
